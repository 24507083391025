@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
:root {
  --primary-color: #5e423e;
  --secondary-color: #f2ccc1;
  --extra-color: #d2d3d5;
  --accent-color: #eebc34;
  --success-color: #2ecc71;
  --error-color: #e74c3c;
}

.text-primary {
  color: var(--accent-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.banner {
  color: var(--error-color);
  justify-content: center;
  align-items: center;
  gap: 2em;
  display: flex;
}

.btn {
  cursor: pointer;
  color: #fff;
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
  padding: 10px 30px;
  display: inline-block;
}

.btn:hover {
  opacity: .9;
}

.btn-primary, .bg-primary {
  background: var(--accent-color);
  color: #fff;
}

.btn-secondary, .bg-secondary {
  background: var(--secondary-color);
  color: #fff;
}

.btn-dark, .bg-dark {
  color: #fff;
  background: #333;
}

.btn-light, .bg-light {
  color: #333;
  background: #f4f4f4;
}

.btn-outline {
  background: none;
  border: 1px solid #fff;
}

.flex-items {
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.flex-items > div {
  padding: 20px;
}

.flex-columns.flex-reverse .row {
  flex-direction: row-reverse;
}

.flex-columns .row {
  width: 100%;
  flex-flow: wrap;
  display: flex;
}

.flex-columns .column {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.flex-columns .column .column-2 {
  height: 100%;
}

.flex-columns img {
  width: 100%;
  object-fit: cover;
}

.flex-columns .column-2 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  display: flex;
}

.flex-columns h2 {
  font-size: 40px;
  font-weight: 100;
}

.flex-columns h4 {
  margin-bottom: 10px;
}

.flex-columns p {
  margin: 20px 0;
}

.section-header {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

.section-header h2 {
  margin: 20px 0;
  font-size: 40px;
}

.section-padding {
  padding: 20px 20px 40px;
}

.flex-grid .row {
  flex-wrap: wrap;
  padding: 0 4px;
  display: flex;
}

.flex-grid .column {
  max-width: 25%;
  flex: 25%;
  padding: 0 4px;
}

.card {
  width: 25vw;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 40px;
  padding: 20px;
  display: flex;
  box-shadow: 3px 3px 10px 1px #0000004d;
}

/*# sourceMappingURL=index.262d20a7.css.map */
