@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

:root {
  --primary-color: #5e423e;
  --secondary-color: #f2ccc1;
  --extra-color: #d2d3d5;
  --accent-color: #eebc34;
  --success-color: #2ecc71;
  --error-color: #e74c3c;
}

/* Text Colors */
.text-primary {
  color: var(--accent-color);
}

.text-secondary {
  color: var(--secondary-color);
}

/* Banner */
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  color: var(--error-color);
}

/* Button */
.btn {
  cursor: pointer;
  display: inline-block;
  padding: 10px 30px;
  color: #fff;
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
}

.btn:hover {
  opacity: 0.9;
}

.btn-primary,
.bg-primary {
  background: var(--accent-color);
  color: #fff;
}

.btn-secondary,
.bg-secondary {
  background: var(--secondary-color);
  color: #fff;
}

.btn-dark,
.bg-dark {
  background: #333;
  color: #fff;
}

.btn-light,
.bg-light {
  background: #f4f4f4;
  color: #333;
}

.btn-outline {
  background: transparent;
  border: 1px solid #fff;
}

/* Flex Items */
.flex-items {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: stretch;
  height: 100%;
}

.flex-items > div {
  padding: 20px;
}

/* Flex Columns */
.flex-columns.flex-reverse .row {
  flex-direction: row-reverse;
}

.flex-columns .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-columns .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.flex-columns .column .column-2 {
  height: 100%;
}

.flex-columns img {
  width: 100%;
  object-fit: cover;
}

.flex-columns .column-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
}

.flex-columns h2 {
  font-size: 40px;
  font-weight: 100;
}

.flex-columns h4 {
  margin-bottom: 10px;
}

.flex-columns p {
  margin: 20px 0;
}

/* Section Header */
.section-header {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-header h2 {
  font-size: 40px;
  margin: 20px 0;
}

.section-padding {
  padding: 20px 20px 40px;
}

/* Flex Grid */
.flex-grid .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.flex-grid .column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

/* Cards */
.card {
  display: flex;
  flex-direction: column;
  width: 25vw;
  border: 1px solid var(--accent-color);
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 40px;
}
